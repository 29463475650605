html,
body {
    height: 100%;
}

#root {
    height: 100%;
}

.App {
    text-align: center;
    height: 100%;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.inferno-logo {
    background: #fff;
    width: 6rem;
    height: 6rem;
    padding: 2rem;
    border-radius: 2rem;
}

.App-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    padding: 30px;
}

.App-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.App-link {
    color: #fff;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.transcript {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.transcript .text {
    text-align: justify;
    margin-right: 15px;
}

.transcript .author {
    width: fit-content;
}

.speaker-separator {
    margin-top: 10px;
}

.transcript .photo {
    width: 50px;
    min-width: 50px;
}

.mono-speaker .transcript .photo {
    width: 20px;
    min-width: 20px;
}

.mono-speaker .transcript .photo img {
    display: none;
}

.transcript-container {
    padding: 10px;
    align-self: start;
    padding-bottom: 5px;
    padding-left: 0px;
}

.toolbar {
    position: fixed;
    top: 20px;
    right: 50px;
    font-size: 32px;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    border-style: solid;
    border-width: 1px;
    opacity: 0.9;
}

.toolbar button {
    font-size: 20px;
    margin-left: 5px;
    margin-right: 5px;
}

.no-button {
    border-style: none;
    background-color: rgba(0, 0, 0, 0);
    color: inherit;
    cursor: pointer;
    padding: 0px;
}

.no-button:focus {
    outline: none;
}

.toolbar i {
    border-radius: 10px;
}
.toolbar i:hover {
    color: grey;
}

body,
.theme-dark {
    background-color: #0f1316;
    color: white;
}
i {
    background-color: inherit;
    color: rgb(233, 233, 233);
}
.toolbar {
    background-color: rgb(33, 33, 33);
}
.follow-button {
    background-color: rgba(33, 33, 33, 0.9);
}

.theme-light {
    background-color: rgb(233, 233, 233);
    color: rgb(33, 33, 33);
}
.theme-light i {
    background-color: inherit;
    color: rgb(33, 33, 33);
}
.theme-light .toolbar {
    background-color: rgb(233, 233, 233);
}
.theme-light .follow-button {
    background-color: rgba(233, 233, 233, 0.9);
}

.toolbar.visible {
    visibility: visible;
    opacity: 0.9;
    transition: opacity 0.5s linear;
}

.toolbar.hidden {
    visibility: hidden;
    opacity: 0;
    transition:
        visibility 0s 0.5s,
        opacity 0.5s linear;
}

.follow-button {
    position: fixed;
    right: 30px;
    bottom: 30px;
    border-style: solid;
    border-radius: 25px;
    border-width: 1px;
    width: 30px;
    height: 30px;
}

.follow-button i {
    line-height: 30px;
}

.footer {
    flex-grow: 1;
    min-height: 15px;
}
